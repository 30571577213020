import styled from 'styled-components';

import { getThemeColor, media } from '@utils/styled';

import Typography from '@common/components/Typography';
import {
  Voucher as VoucherIcon,
  Gift as GiftIcon,
  Stats as StatsIcon,
  Heart as HeartIcon,
  MobilePhone as MobilePhoneIcon,
} from '@common/components/Icons';
import { STICKY_HEADER_HEIGHT } from '@common/constants/ui';
import { Hr } from '@common/components/Hr';

export const BARCODE_HEIGHT = 200;

export const Layout = styled.section`
  display: flex;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.getFluidAttribute('width', 9.6, 18.2)};
  margin: 0 auto;
`;

export const CustomerInfo = styled(Typography).attrs({
  variant: 'h3',
})`
  text-align: center;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 5)};
`;

export const Video = styled.video`
  position: relative;
  display: block;
  width: auto;
  min-width: 100%;
  height: 100%;
  background: transparent url(${({ srcFallback }) => srcFallback}) no-repeat;
  background-size: cover;
  pointer-events: none;
  object-fit: cover;
`;

export const HeadSection = styled.div`
  position: relative;
  left: calc(-100vw / 2 + 50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  margin-bottom: -${STICKY_HEADER_HEIGHT}rem;
  padding-top: ${STICKY_HEADER_HEIGHT}rem;
`;

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: -100;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const UserButtonsWrapper = styled.div`
  position: absolute;
  ${({ theme }) => theme.getFluidSpacing('top', 'scale', 7)};
  ${({ theme }) => theme.getFluidSpacing('right', 'widthBased', 0, false, 'safe-area-inset-right')};
  z-index: 18;
  display: flex;

  & > button {
    ${({ theme }) => theme.getFluidSpacing('margin-left', 'equal', 5)};
  }
`;

export const VouchersWrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 2)};
`;

export const VoucherTitleValueWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const ScoreTitle = styled(Typography)`
  margin: 0;
  color: ${getThemeColor('bluegrey.70')};
  font-weight: 500;
  white-space: nowrap;
`;

export const ScoreValueMax = styled(Typography)`
  color: ${getThemeColor('bluegrey.80')};
  font-weight: 500;
`;

export const CardsWrapper = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 0)};
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'widthBased', 1)};
`;

export const WideCardWrapper = styled.div`
  grid-column: span 2;
`;

export const WrapperCentered = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 0)};
`;

export const VIPCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 0)};
  ${({ theme }) => theme.getFluidSpacing('padding', 'widthBased', 2)};
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.1']}rem;
  background-color: ${getThemeColor('bluegrey.95')};

  > :last-child {
    ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 6)};
    margin: 0;
  }
`;

export const VideoGradientOverlay = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  height: calc(10.4rem + 5.4 * (100vw - 32rem) / 144);
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 79%,
    rgba(255, 255, 255, 1) 100%
  );

  ${media.from1760up`
    height: 15.8rem;
  `}
`;

export const VIPCardImageWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const VIPCardImageToggle = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
`;

export const VIPCardImage = styled.img`
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  height: 20.5rem;
  transition: opacity 1ms linear;
  transition-delay: ${({ isHidden }) => (isHidden ? 0 : 690)}ms;
`;

export const CardIconVoucher = styled(VoucherIcon)`
  fill: ${getThemeColor('bluegrey.70')};
  ${({ theme }) => theme.getFluidAttribute('width', 5.7, 8.5)};
`;

export const CardIconGift = styled(GiftIcon)`
  fill: ${getThemeColor('bluegrey.70')};
  ${({ theme }) => theme.getFluidAttribute('width', 3.4, 5.2)};
`;

export const CardIconStats = styled(StatsIcon)`
  fill: ${getThemeColor('bluegrey.70')};
  ${({ theme }) => theme.getFluidAttribute('width', 4.2, 6.3)};
`;

export const CardIconHeart = styled(HeartIcon)`
  fill: ${getThemeColor('bluegrey.70')};
  ${({ theme }) => theme.getFluidAttribute('width', 3.7, 5.5)};
`;

export const CardIconPhone = styled(MobilePhoneIcon)`
  fill: ${getThemeColor('bluegrey.70')};
  ${({ theme }) => theme.getFluidAttribute('width', 3, 4)};
`;

export const HrStyled = styled(Hr)`
  margin-top: 0;
`;
