import PropTypes from 'prop-types';
import React from 'react';

import { VIPUnderlayIcon } from '@common/components/Icons';
import { LOGO_CHICOREE_GOLD } from '@common/images';

import {
  BarcodeImage,
  BarcodeImageContainer,
  BarcodeNumber,
  BarcodeWrapper,
  LogoImage,
  LogoImageWrapper,
  VIPIconWrapper,
  Wrapper,
} from './VIPCardBack.styled';

const prepareBarcodeNumber = number => {
  if (!number) {
    return '';
  }

  return `${number.slice(0, 1)} ${number.slice(1, 5)} ${number.slice(5, 9)} ${number.slice(9, 13)}`;
};

export function VIPCardBack({ className, image, number }) {
  return (
    <Wrapper className={className}>
      <LogoImageWrapper>
        <LogoImage
          alt="chicoree logo"
          src={LOGO_CHICOREE_GOLD}
        />
      </LogoImageWrapper>
      <BarcodeWrapper>
        <VIPIconWrapper>
          <VIPUnderlayIcon />
        </VIPIconWrapper>
        <BarcodeImageContainer>
          <BarcodeImage
            alt="barcode"
            src={image}
          />
        </BarcodeImageContainer>
      </BarcodeWrapper>
      <BarcodeNumber>{prepareBarcodeNumber(number)}</BarcodeNumber>
    </Wrapper>
  );
}

VIPCardBack.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

VIPCardBack.defaultProps = {
  className: undefined,
};

export default VIPCardBack;
