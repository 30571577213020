import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';

import { selectIsUserLogged } from '@selectors/auth';
import { selectBarcodeImage } from '@selectors/barcode';
import { selectCardNr } from '@selectors/customerInfo';
import { fetchBarcode } from '@thunks/barcode';
import { fetchCustomerInfo } from '@thunks/customerInfo';

import { BARCODE_HEIGHT } from '@routes/VIPHome/VIPHome.styled';

import useIsomorphicLayoutEffect from '@common/hooks/useIsomorphicLayoutEffect';
import { VIP_CARD } from '@common/images';
import { VIPCardBack } from '@common/components/VIPCard';

import {
  FlipCardContainer,
  FlipVIPCardBack,
  FlipVIPCardFront,
  VIPCardImage,
} from './FlipVipCard.styled';

function calculateStartPosition(vipCardEl, cb) {
  if (!vipCardEl) {
    return;
  }

  const VIPCardTopPosition = vipCardEl.getBoundingClientRect().top;
  const viewportCenter = document.documentElement.clientHeight / 2;
  const barcodeStartPosition = -viewportCenter + VIPCardTopPosition + BARCODE_HEIGHT / 2;

  cb(barcodeStartPosition);
}

const FlipVipCard = ({ isOpen, vipCardRef, shouldSlideIn }) => {
  const [startPosition, setStartPosition] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsUserLogged);
  const barcodeImageUrl = useSelector(selectBarcodeImage);
  const barcodeNumber = useSelector(selectCardNr);

  useEffect(() => {
    if (isLoggedIn && (!barcodeNumber || !barcodeImageUrl)) {
      dispatch(fetchBarcode());
      dispatch(fetchCustomerInfo());
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    calculateStartPosition(vipCardRef?.current, setStartPosition);
  }, [isOpen]);

  return (
    <FlipCardContainer>
      <FlipVIPCardFront
        withFadeIn={!vipCardRef}
        startTop={`${startPosition / 10}rem`}
        shouldSlideIn={shouldSlideIn}
      >
        <VIPCardImage
          alt={t('vipHome.vipCard', 'VIP-Karte')}
          src={VIP_CARD}
        />
      </FlipVIPCardFront>
      <FlipVIPCardBack
        withFadeIn={!vipCardRef}
        startTop={`${startPosition / 10}rem`}
        shouldSlideIn={shouldSlideIn}
      >
        <VIPCardBack
          image={barcodeImageUrl}
          number={barcodeNumber}
        />
      </FlipVIPCardBack>
    </FlipCardContainer>
  );
};

FlipVipCard.propTypes = {
  isOpen: PropTypes.bool,
  vipCardRef: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.any,
  }),
  shouldSlideIn: PropTypes.bool,
};

FlipVipCard.defaultProps = {
  isOpen: false,
  vipCardRef: undefined,
  shouldSlideIn: false,
};

export default FlipVipCard;
